<template>
  <LayoutMainPages v-if="asset">
    <main class="take-custody-option">
      <h1 class="headline-large mb-s24">
        {{ details.title }}
      </h1>

      <h2 class="subheadline-large mb-s12">
        {{ details.subtitle }}
      </h2>

      <p class="take-custody-option-details" v-html="details.description"></p>
    
      <section class="mb-s48">
        <BasicInput
          v-if="input"
          v-model="vaultInput"
          :placeholder="input"
          class="take-custody-input"
        />

        <p
          v-else-if="address"
          v-html="address"
          class="body-text-large"
        />
      </section>

      <section>
        <h3 class="subheadline-large mb-s32">
          {{ $t('take_custody.asset.title') }}
        </h3>

        <Card class="take-custody-asset">
          <section class="flex items-center">
            <img class="take-custody-asset-thumbnail" :src="asset.thumbnail" />

            <section>
              <h4 class="take-custody-asset-title">
                {{ asset.title }}
              </h4>
            </section>
          </section>

          <p class="subheadline-medium">
            {{ asset.price }}
          </p>
        </Card>

        <section class="flex justify-end">
          <ButtonV2
            :label="$tc('take_custody.asset.trigger')"
            version="primary"
            size="medium"
            :inactive="buttonInactive"
            @onClick="requestTransfer"
          />
        </section>
      </section>
    </main>
  </LayoutMainPages>
</template>

<script>
import { mapGetters } from 'vuex';
import { formatMoney } from '@/utils/formatters';

import {
  BasicInput,
  ButtonV2,
  Card,
  LayoutMainPages,
} from '@/components/misc';

export default {
  name: 'TakeCustody',

  components: {
    BasicInput,
    ButtonV2,
    Card,
    LayoutMainPages,
  },

  created() {
    if (!this.asset) {
      this.$router.push({ name: 'take-custody' });
    }
  },

  mounted() {
    this.option = this.$route.params.option;
    this.buttonInactive = this.option === 'vault';
  },

  data() {
    return {
      option: null,
      vaultInput: '',
      buttonInactive: true,
    };
  },

  watch: {
    vaultInput() {
      this.buttonInactive = !this.vaultInput;
    }
  },

  computed: {
    ...mapGetters({ currentUser: 'api/getCurrentUser'}),

    asset() {
      const asset = this.$route.params.asset || JSON.parse(localStorage.getItem('takeCustodyAsset'));

      return {
        ...asset,
        thumbnail: asset.base.img_front,
        title: asset.base.name,
        price: formatMoney(asset.base.price_usd,0),
      };
    },

    address() {
      const {
        address_street,
        address_city,
        address_state,
        address_country,
        address_zip
      } = this.currentUser.user_info;

      return this.option === 'ship'
        ? `${ address_street}<br>${address_city}, ${address_state}, ${address_country} - ${address_zip}`
        : null;
    },

    input() {
      return this.option === 'vault'
        ? this.$t(`take_custody.options.${ this.option }.input`)
        : null;
    },

    details() {
      return {
        title: this.$t(`take_custody.options.${ this.option }.title`),
        subtitle: this.$t(`take_custody.options.${ this.option }.subtitle`),
        description: this.$t(`take_custody.options.${ this.option }.description`),
      };
    },
  },
  
  methods: {
    requestTransfer() {
      const feedback = {
        pairId: this.asset.id,
        cardName: this.asset.base.name,
        cardPrice: this.asset.base.price_usd,
        pwccIdentityKey: this.vaultInput,
      };

      this.showModal('TakeCustodyModal', { 
        type: 'confirm',
        feedback
      });
    }
  }
};
</script>

<style scoped>
.take-custody-option {
  max-width: 750px;
  width: 100%;
}

.take-custody-option-details {
  @apply body-text-large mb-s24;
}

.take-custody-option-details >>> a {
  @apply text-text-active-2;
}

.take-custody-asset {
  @apply flex justify-between items-center py-s12 px-s20 mb-s32;
}

.take-custody-asset-title {
  @apply body-text-large;
  max-width: 300px;
}

.take-custody-asset-thumbnail {
  @apply mr-s16;
  max-width: 35px
}

.take-custody-input {
  @apply border-border bg-background-primary;
  width: 100%;
}
</style>
